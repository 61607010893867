import React from 'react'
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Dock2 from 'assets/images/Dock2.png';
import OuterRingImage from 'assets/images/OuterRing.png'
import Header from 'front/Header/Header';
import Footer from 'front/Footer/Footer';
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  NavbarToggler,
  ModalHeader,
  Row,
  Card,
  CardHeader,
  CardBody,
  Col,
  CardTitle,
  CardText,
  Table,
  ModalBody
} from "reactstrap";

import SuitDiagnosticsData from './SuitDiagnostics.json';
import './SuitDiagnostics.css'
import SpeedoMeter from './SpeedoMeter';

export default function SuitDiagnostics() {
  const [headerTitle, setHeaderTitle] = useState("FULL SUIT SYSTEM OVERVIEW");
  const [currentImage, setCurrentImage] = useState("https://bioastroause.blob.core.windows.net/suit/vyom/vyom_(2x3).png");
  const [connectionData, setConnectionData] = useState(SuitDiagnosticsData.connections);
  const location = useLocation();

  const handleFooterNavClick = (text, data, imagePath) => {
    console.log(imagePath)
    setHeaderTitle(text);
    setConnectionData(data);
    setCurrentImage(imagePath);
  };

  return (

    <div>
      <Header currentRoute={location.pathname}></Header>
      <Container fluid
      // style={{background:'#1E213A'}}
      >
        <header className='text-center'>
          <h6> {headerTitle}</h6>
        </header>
        <Row>
          <Col md="3" sm="3" lg="3">
            {SuitDiagnosticsData.systemChecks.map((systemCheck, index) => (
              <Row key={index}>
                <Col className='' md="1">
                  <div className="ml-1 round">
                    <input type="checkbox" id={`checkbox${index}`} />
                    <label for={`checkbox${index}`}></label>
                  </div>
                </Col>
                <Col md="10">
                  <Row>
                    <Col className='mb-2' md="12">
                      <p className=''>
                        {systemCheck.name}
                      </p>
                      <label className={
                        systemCheck.status === 'ACTIVE' ? 'text-success' :
                          systemCheck.status === 'RUN CHECK' ? 'text-warning' :
                            systemCheck.status === 'OFFLINE' ? 'text-danger' : ''
                      }>{systemCheck.status}</label>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
          </Col>
          <Col md="6">
            <Row>
              <Col md="3">
                <Card className='diagnostic-card'>
                  {/* <div className="gauge text-center">
                    <svg width="100" height="100" viewBox="0 0 36 36">
                      <circle
                        className="gauge-back"
                        cx="18"
                        cy="18"
                        r="15.91549430918954" // This radius corresponds to a circumference of 100
                        fill="none"
                        stroke="#4f5b66"
                        strokeWidth="1"
                        strokeLinecap="round"
                      ></circle>
                      <path
                        className="gauge-value"
                        d="M18 2.0845
              a 15.91549430918954 15.91549430918954 0 0 1 0 31.831
              a 15.91549430918954 15.91549430918954 0 0 1 0 -31.831"
                        fill="none"
                        stroke="#FFD700"
                        strokeWidth="2"
                        strokeLinecap="butt"
                        strokeDasharray="25, 100"
                      ></path>
                      <text
                        x="18"
                        y="14"
                        className="gauge-label"
                        alignmentBaseline="middle"
                        textAnchor="middle"
                        fill="#fff"
                        fontSize="3"
                      >
                        PPO2
                      </text>
                      <text
                        x="18"
                        y="22"
                        className="gauge-value-text"
                        alignmentBaseline="middle"
                        textAnchor="middle"
                        fill="#fff"
                        fontSize="5"
                      >
                        2.69 psia
                      </text>
                    </svg>
                  </div> */}
                  <SpeedoMeter value="#CFF80B" />
                  {/* <div className='text-center'>
                   <img  src={OuterRingImage} width={100} height={'auto'} ></img>
                  </div> */}
                </Card>
              </Col>
              <Col md="3">
                <Card className='diagnostic-card'>
                  <div className="mt-2 gauge text-center">
                    <svg width="100" height="100" viewBox="0 0 36 36">
                      <circle
                        className="gauge-back"
                        cx="18"
                        cy="18"
                        r="15.91549430918954" // This radius corresponds to a circumference of 100
                        fill="none"
                        stroke="#4f5b66"
                        strokeWidth="1"
                        strokeLinecap="round"
                      ></circle>
                      <path
                        className="gauge-value"
                        d="M18 2.0845
              a 15.91549430918954 15.91549430918954 0 0 1 0 31.831
              a 15.91549430918954 15.91549430918954 0 0 1 0 -31.831"
                        fill="none"
                        stroke="#FFD700"
                        strokeWidth="2"
                        strokeLinecap="butt"
                        strokeDasharray="25, 100"
                      ></path>
                      <text
                        x="18"
                        y="14"
                        className="gauge-label"
                        alignmentBaseline="middle"
                        textAnchor="middle"
                        fill="#fff"
                        fontSize="3"
                      >
                        PPO2
                      </text>
                      <text
                        x="18"
                        y="22"
                        className="gauge-value-text"
                        alignmentBaseline="middle"
                        textAnchor="middle"
                        fill="#fff"
                        fontSize="5"
                      >
                        2.69 psia
                      </text>
                    </svg>
                  </div>
                </Card>
              </Col>
              <Col md="3">
                <Card className='diagnostic-card'>
                  <div className="mt-2 gauge text-center">
                    <svg width="100" height="100" viewBox="0 0 36 36">
                      <circle
                        className="gauge-back"
                        cx="18"
                        cy="18"
                        r="15.91549430918954" // This radius corresponds to a circumference of 100
                        fill="none"
                        stroke="#4f5b66"
                        strokeWidth="1"
                        strokeLinecap="round"
                      ></circle>
                      <path
                        className="gauge-value"
                        d="M18 2.0845
              a 15.91549430918954 15.91549430918954 0 0 1 0 31.831
              a 15.91549430918954 15.91549430918954 0 0 1 0 -31.831"
                        fill="none"
                        stroke="#FFD700"
                        strokeWidth="2"
                        strokeLinecap="butt"
                        strokeDasharray="25, 100"
                      ></path>
                      <text
                        x="18"
                        y="14"
                        className="gauge-label"
                        alignmentBaseline="middle"
                        textAnchor="middle"
                        fill="#fff"
                        fontSize="3"
                      >
                        PPO2
                      </text>
                      <text
                        x="18"
                        y="22"
                        className="gauge-value-text"
                        alignmentBaseline="middle"
                        textAnchor="middle"
                        fill="#fff"
                        fontSize="5"
                      >
                        2.69 psia
                      </text>
                    </svg>
                  </div>
                </Card>
              </Col>
              <Col md="3">
                <Card className='diagnostic-card'>
                  <SpeedoMeter value="#01426A" />
                </Card>
              </Col>
            </Row>
            <Row>
              {/* <Col md="2">
                <Card>
                  <div className="gauge text-center">
                    <svg width="100" height="100" viewBox="0 0 36 36">
                      <circle
                        className="gauge-back"
                        cx="18"
                        cy="18"
                        r="15.91549430918954" // This radius corresponds to a circumference of 100
                        fill="none"
                        stroke="#4f5b66"
                        strokeWidth="1"
                        strokeLinecap="round"
                      ></circle>
                      <path
                        className="gauge-value"
                        d="M18 2.0845
              a 15.91549430918954 15.91549430918954 0 0 1 0 31.831
              a 15.91549430918954 15.91549430918954 0 0 1 0 -31.831"
                        fill="none"
                        stroke="#FFD700"
                        strokeWidth="2"
                        strokeLinecap="butt"
                        strokeDasharray="25, 100"
                      ></path>
                      <text
                        x="18"
                        y="14"
                        className="gauge-label"
                        alignmentBaseline="middle"
                        textAnchor="middle"
                        fill="#fff"
                        fontSize="3"
                      >
                        PPO2
                      </text>
                      <text
                        x="18"
                        y="22"
                        className="gauge-value-text"
                        alignmentBaseline="middle"
                        textAnchor="middle"
                        fill="#fff"
                        fontSize="5"
                      >
                        2.69 psia
                      </text>
                    </svg>
                  </div>
                </Card>
              </Col> */}
              {/* <Col md="2">
                <Card>
                  <div className="gauge text-center">
                    <svg width="100" height="100" viewBox="0 0 36 36">
                      <circle
                        className="gauge-back"
                        cx="18"
                        cy="18"
                        r="15.91549430918954" // This radius corresponds to a circumference of 100
                        fill="none"
                        stroke="#4f5b66"
                        strokeWidth="1"
                        strokeLinecap="round"
                      ></circle>
                      <path
                        className="gauge-value"
                        d="M18 2.0845
              a 15.91549430918954 15.91549430918954 0 0 1 0 31.831
              a 15.91549430918954 15.91549430918954 0 0 1 0 -31.831"
                        fill="none"
                        stroke="#FFD700"
                        strokeWidth="2"
                        strokeLinecap="butt"
                        strokeDasharray="25, 100"
                      ></path>
                      <text
                        x="18"
                        y="14"
                        className="gauge-label"
                        alignmentBaseline="middle"
                        textAnchor="middle"
                        fill="#fff"
                        fontSize="3"
                      >
                        PPO2
                      </text>
                      <text
                        x="18"
                        y="22"
                        className="gauge-value-text"
                        alignmentBaseline="middle"
                        textAnchor="middle"
                        fill="#fff"
                        fontSize="5"
                      >
                        2.69 psia
                      </text>
                    </svg>
                  </div>
                </Card>
              </Col> */}
              <Col md="8">
                <Row>
                  <Col className='' md="7">
                    <div class="connections-data">
                      {/* <h5>CONNECTIONS</h5> */}
                      {/* <hr style={{background:'#9499C3'}}></hr> */}
                      {connectionData.map((connection, index) => (
                        <div key={index}>
                          <h6>{connection.title}</h6>
                          {connection.subData ? (
                            // If there is a subData array, loop through it
                            connection.subData.map((sub, subIndex) => (
                              <Row key={subIndex}>
                                <Col md="7">
                                  <p>{sub.title}</p>
                                </Col>
                                <Col md="5">
                                  <p>{sub.value || sub.unit}</p>
                                </Col>
                              </Row>
                            ))
                          ) : (
                            // Otherwise, just display the title and value of the connection
                            <Row>
                              <Col md="7">
                                <p>{connection.title}</p>
                              </Col>
                              <Col md="5">
                                <p>{connection.value}</p>
                              </Col>
                            </Row>
                          )}
                        </div>
                      ))}
                    </div>
                  </Col>
                  <Col className='' md="5">
                    <div class="diagnostics-image">
                      <img src={currentImage} className="suit-img " style={{width:'auto',height:'50vh'}} />
                    </div>
                  </Col>
                </Row>
              </Col>
              {/* <Col md="2">
                <Card>
                  <div className="gauge text-center">
                    <svg width="100" height="100" viewBox="0 0 36 36">
                      <circle
                        className="gauge-back"
                        cx="18"
                        cy="18"
                        r="15.91549430918954" // This radius corresponds to a circumference of 100
                        fill="none"
                        stroke="#4f5b66"
                        strokeWidth="1"
                        strokeLinecap="round"
                      ></circle>
                      <path
                        className="gauge-value"
                        d="M18 2.0845
              a 15.91549430918954 15.91549430918954 0 0 1 0 31.831
              a 15.91549430918954 15.91549430918954 0 0 1 0 -31.831"
                        fill="none"
                        stroke="#FFD700"
                        strokeWidth="2"
                        strokeLinecap="butt"
                        strokeDasharray="25, 100"
                      ></path>
                      <text
                        x="18"
                        y="14"
                        className="gauge-label"
                        alignmentBaseline="middle"
                        textAnchor="middle"
                        fill="#fff"
                        fontSize="3"
                      >
                        PPO2
                      </text>
                      <text
                        x="18"
                        y="22"
                        className="gauge-value-text"
                        alignmentBaseline="middle"
                        textAnchor="middle"
                        fill="#fff"
                        fontSize="5"
                      >
                        2.69 psia
                      </text>
                    </svg>
                  </div>
                </Card>
              </Col> */}
              {/* <Col md="2">
                <Card>
                  <div className="gauge text-center">
                    <svg width="100" height="100" viewBox="0 0 36 36">
                      <circle
                        className="gauge-back"
                        cx="18"
                        cy="18"
                        r="15.91549430918954" // This radius corresponds to a circumference of 100
                        fill="none"
                        stroke="#4f5b66"
                        strokeWidth="1"
                        strokeLinecap="round"
                      ></circle>
                      <path
                        className="gauge-value"
                        d="M18 2.0845
              a 15.91549430918954 15.91549430918954 0 0 1 0 31.831
              a 15.91549430918954 15.91549430918954 0 0 1 0 -31.831"
                        fill="none"
                        stroke="#FFD700"
                        strokeWidth="2"
                        strokeLinecap="butt"
                        strokeDasharray="25, 100"
                      ></path>
                      <text
                        x="18"
                        y="14"
                        className="gauge-label"
                        alignmentBaseline="middle"
                        textAnchor="middle"
                        fill="#fff"
                        fontSize="3"
                      >
                        PPO2
                      </text>
                      <text
                        x="18"
                        y="22"
                        className="gauge-value-text"
                        alignmentBaseline="middle"
                        textAnchor="middle"
                        fill="#fff"
                        fontSize="5"
                      >
                        2.69 psia
                      </text>
                    </svg>
                  </div>
                </Card>
              </Col> */}
            </Row>
          </Col>
          <Col md="3">

            {SuitDiagnosticsData.flightData.map((flight, index) => (
              <Row>
                <Col className='mb-2' md="12">
                  <div class="progress-bar-container">
                    <div class="label">
                      <span>{flight.metric}</span>
                      <span>{flight.value} {flight.unit}</span>
                    </div>
                    <div class="progress-bar">
                      <progress className={`progress ${flight.slug}`} value={flight.value} max="100"></progress>
                    </div>
                  </div>
                </Col>

              </Row>
            ))}

          </Col>
        </Row>
        <Row >
          {/* <Col className='pb-5' md="3">
          <Button className='suit-diagnostics'>System</Button>
          <Button className='suit-diagnostics'>Cabin</Button>
        </Col> */}
          <Col className='' md="12">
            <Card className=''>
              <Row>
                {SuitDiagnosticsData.footerNav.map((footer, index) => (
                  // <Row>
                  <Col className='1'>
                    <Row>
                      <Col md="12"
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleFooterNavClick(footer.text, footer.data, require(`assets/images/${footer.image}`))}>
                        {/* <i width={100} height={100} class={`text-white ${footer.icon}`}></i> */}
                        <img src={require(`assets/images/${footer.image}`)} width={100} height={100} alt={footer.text} className="zoom-effect" />
                      </Col>
                      <Col md="12">
                        <p className='text-center' href={footer.href}>{footer.text}</p>
                      </Col>
                    </Row>
                  </Col>
                ))}

              </Row>

            </Card>
          </Col>
          {/* <Col md="2">
          <h6>Power,Engine,Comms</h6>
        </Col> */}
        </Row>
      </Container>
      <Footer></Footer>
    </div>
  )
}

